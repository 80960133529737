import { format, parseISO } from 'date-fns';
import { forwardRef } from 'react';
import type { HTMLAttributes, ReactNode } from 'react';

import { cn } from '~/libs/utils';

export interface DateDisplayProps {
  value: string | Date;
  format: string;
  parseOptions?: Parameters<typeof parseISO>[1];
  formatOptions?: Parameters<typeof format>[2];
}

export const DateDisplay = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement> & Readonly<DateDisplayProps>>(
  ({ value, format: dateFormat, formatOptions, parseOptions, className, ...props }, ref): ReactNode => {
    return (
      <span ref={ref} className={cn('slashed-zero lining-nums tabular-nums', className)} {...props}>
        {format(parseISO(value as string, parseOptions), dateFormat, formatOptions)}
      </span>
    );
  }
);
